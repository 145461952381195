<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('movement.program_no')"
                    label-for="program_no"
                    >
                    <b-form-input
                        id="program_no"
                        v-model="search.program_no"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_godown_infos_id"
                      >
                      <template v-slot:label>
                      {{ $t('movement.fromGodown') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.from_godown_infos_id"
                        :options="godownInfoList"
                        id="godown_infos_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="to_godown_infos_id"
                      >
                      <template v-slot:label>
                      {{ $t('movement.toGodown') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.to_godown_infos_id"
                        :options="godownInfoList"
                        id="to_godown_infos_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="transport_agent_id"
                      >
                      <template v-slot:label>
                      {{ $t('movement.transport_agent') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.transport_agent_id"
                        :options="transportAgentList"
                        id="transport_agent_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('movement.interGodownDelivery')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(program_no)="data">
                                          {{ $n(data.item.program_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(delivered_amount)="data">
                                          {{ $n(data.item.delivered_amount, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(received_amount)="data">
                                          {{ $n(data.item.received_amount, { useGrouping: false }) }}
                                        </template>+
                                        <template v-slot:cell(amount_of_fertilizer)="data">
                                          {{ $n(data.item.amount_of_fertilizer, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                           </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 title="View" @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)" v-if="data.item.status === 1"><i class="fas fa-pen"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Rejsect" @click="remove(data.item)" v-if="data.item.status === 1"><i class="fas fa-times"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Approve" v-if="data.item.status == 1" @click="finalSave(data.item)"><i class="fas fa-check"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('movement.interGodownDelivery')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('movement.interGodownDelivery') }}
            <b-button variant="light" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interGodownDeliveryList, interGodownDeliveryFinalSave, interGodownDeliveryDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                from_godown_infos_id: 0,
                to_godown_infos_id: 0,
                transport_agent_id: 0,
                program_no: ''
            }
        }
    },
    computed: {
        fertilizerNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        transportAgentList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
        },
        godownInfoList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('movement.interGodownDelivery') + ' ' + this.$t('globalTrans.entry') : this.$t('movement.interGodownDelivery') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('movement.program_no'), class: 'text-center' },
                { label: this.$t('movement.fertilizer_name'), class: 'text-center' },
                { label: this.$t('movement.loriChalanNo'), class: 'text-center' },
                { label: this.$t('movement.fromGodown'), class: 'text-center' },
                { label: this.$t('movement.toGodown'), class: 'text-center' },
                { label: this.$t('movement.transport_agent'), class: 'text-center' },
                { label: this.$t('movement.transferAbleAmount'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'program_no' },
                { key: 'fertilizer_name_bn' },
                { key: 'lori_challan_no' },
                { key: 'from_godown_bn' },
                { key: 'to_godown_bn' },
                { key: 'transport_agent_bn' },
                { key: 'amount_of_fertilizer' },
                { key: 'action' },
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'program_no' },
                { key: 'fertilizer_name' },
                { key: 'lori_challan_no' },
                { key: 'from_godown' },
                { key: 'to_godown' },
                { key: 'transport_agent' },
                { key: 'amount_of_fertilizer' },
                { key: 'action' },
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, interGodownDeliveryDestroy, item)
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, interGodownDeliveryFinalSave, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, interGodownDeliveryList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const transportObj = this.transportAgentList.find(doc => doc.value === item.transport_agent_id)
            const transportData = {}
            if(typeof transportObj !== 'undefined') {
              transportData.transport_agent = transportObj.text_en
              transportData.transport_agent_bn = transportObj.text_bn
            } else {
              transportData.transport_agent = ''
              transportData.transport_agent_bn = ''
            }

            const fromGodownObj = this.godownInfoList.find(doc => doc.value === item.from_godown_id)
            const fromGodownData = {}
            if(typeof fromGodownObj !== 'undefined') {
              fromGodownData.from_godown = fromGodownObj!== 'undefined' ? fromGodownObj.text_en : ''
              fromGodownData.from_godown_bn = fromGodownObj!== 'undefined' ? fromGodownObj.text_bn : ''
            } else {
              fromGodownData.from_godown = ''
              fromGodownData.from_godown_bn = ''
            }

            const toGodownObj = this.godownInfoList.find(doc => doc.value === item.to_godown_id)
            const toGodownData = {}
            if(typeof toGodownObj !== 'undefined') {
              toGodownData.to_godown = toGodownObj.text_en
              toGodownData.to_godown_bn = toGodownObj.text_bn
            } else {
              toGodownData.to_godown = ''
              toGodownData.to_godown_bn = ''
            }

            const fertilizerObj = this.fertilizerNameList.find(fertilizerItem => fertilizerItem.value === item.fertilizer_id)
            const fertilizerData = {}
            if(typeof fertilizerObj !== 'undefined') {
              fertilizerData.fertilizer_name = fertilizerObj.text_en
              fertilizerData.fertilizer_name_bn = fertilizerObj.text_bn
            } else {
              fertilizerData.fertilizer_name = ''
              fertilizerData.fertilizer_name_bn = ''
            }
            return Object.assign({}, item, transportData, fromGodownData, toGodownData, fertilizerData)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
