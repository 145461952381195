<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                          <!-- <b-col lg="6" sm="12">
                            <ValidationProvider name="Lori Challan No" vid="lori_challan_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="allocation_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.loriChalanNo')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="memo_no"
                                    v-model="formData.lori_challan_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No"  vid="program_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="program_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    v-model="formData.program_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="fertilizer_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fertilizer_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.fertilizer_id"
                                  :options="fertilizerNameList"
                                  id="fertilizer_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="From Godown"  vid="from_godown_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="from_godown_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fromGodown')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.from_godown_id"
                                  :options="godownInfoList"
                                  id="from_godown_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="id ? true: false"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="To Godown"  vid="to_godown_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="to_godown_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.toGodown')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.to_godown_id"
                                  :options="toGodownList"
                                  id="to_godown_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.districtName')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="district_name"
                                  v-model="formData.district_name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.regionName')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="region_name"
                                  v-model="formData.region_name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Transport Agent"  vid="transport_agent_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transport_agent_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.transport_agent')}}  <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.transport_agent_id"
                                  :options="transportAgentList"
                                  id="transport_agent_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="amount_of_fertilizer"
                            >
                              <template v-slot:label>
                                {{$t('movement.transferAbleAmount')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="amount_of_fertilizer"
                                  v-model="formData.transfer_able_amount"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="transfer_able_bag"
                            >
                              <template v-slot:label>
                                {{$t('movement.transferableBag')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="transfer_able_bag"
                                  v-model="formData.transfer_able_bag"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="12">
                            <div class="card">
                              <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                                {{ $t('movement.loriInformation') }}
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <br/>
                        <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Vehicle No"  vid="vehicle_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="vehicle_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.vehicle_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="vehicle_no"
                                    v-model="formData.vehicle_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Driver Name"  vid="driver_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="driver_name"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.driverName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="driver_name"
                                    v-model="formData.driver_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Phone No"  vid="phone_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="phone_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.phone_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="phone_no"
                                    v-model="formData.phone_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Amount Of Fertilizer"  vid="amount_of_fertilizer" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="amount_of_fertilizer"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.amountOfFertilizer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="amount_of_fertilizer"
                                    v-model="formData.amount_of_fertilizer"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Amount Of Bag"  vid="amount_of_bag" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="amount_of_bag"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.amountOfBag')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="amount_of_bag"
                                    v-model="formData.amount_of_bag"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interGodownDeliveryStore, interGodownDeliveryUpdate, interGodownDeliveryProgramInfo } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getProgramInfo1(this.formData.program_no)
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        lori_challan_no: 0,
        program_no: '',
        transport_agent_id: 0,
        from_godown_id: 0,
        to_godown_id: 0,
        fertilizer_id: 0,
        fiscal_year_id: 1,
        vehicle_no: '',
        driver_name: '',
        district_name: '',
        region_name: '',
        phone_no: '',
        transfer_able_amount: '',
        transfer_able_bag: '',
        amount_of_fertilizer: '',
        amount_of_bag: '',
        delivery_office_id: 0
      },
      programInfo: [],
      godownInfo: [],
      transportAgentList: [],
      toGodownList: []
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.from_godown_id': function (newVal, oldVal) {
      if (this.formData.to_godown_id && newVal === this.formData.to_godown_id) {
        this.formData.to_godown_id = 0
      }
    },
    'formData.to_godown_id': function (newVal, oldVal) {
      if (this.formData.from_godown_id && newVal === this.formData.from_godown_id) {
        this.formData.from_godown_id = 0
      }
      this.programInfo = this.getGodownInfo(newVal)
    },
    'formData.program_no': function (newVal, oldVal) {
       this.programInfo = this.getProgramInfo(newVal)
    },
    'formData.amount_of_fertilizer': function (newVal, oldVal) {
      if (newVal > 0) {
         this.formData.amount_of_bag = this.formData.amount_of_fertilizer * 20
       }
      if (this.formData.amount_of_fertilizer > this.formData.transfer_able_amount) {
            const idValAmount = this.formData.amount_of_fertilizer
            const remainingIdVal = Math.floor(idValAmount / 10)
            this.formData.amount_of_fertilizer = remainingIdVal
            this.formData.amount_of_bag = this.formData.amount_of_fertilizer * 20
        }
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${interGodownDeliveryUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, interGodownDeliveryStore, this.formData)
      }
      this.loading = false
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    },
    getProgramInfo (programNo) {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, interGodownDeliveryProgramInfo + programNo).then(response => {
        if (response.success) {
          this.formData.from_godown_id = response.data.from_godown_infos_id
          this.formData.fertilizer_id = response.data.fertilizer_id
          var toGodown = response.data.inter_godown_details
          this.godownInfo = response.data.inter_godown_details
          var bigCities = []
            for (let i = 0; i < toGodown.length; i++) {
                if (toGodown[i].to_godown_infos_id) {
                    bigCities.push(toGodown[i].to_godown_infos_id)
                }
            }
            var god = []
          for (let i = 0; i < bigCities.length; i++) {
                const data = this.godownInfoList.find(doc => doc.value === bigCities[i])
                if (data) {
                    god.push(data)
                }
            }
          this.toGodownList = god
        }
      })
      this.loading = false
    },
    async getProgramInfo1 (programNo) {
      this.loading = true
      await RestApi.getData(seedFertilizerServiceBaseUrl, interGodownDeliveryProgramInfo + programNo).then(response => {
          if (response.success) {
            var toGodown = response.data.inter_godown_details
            this.godownInfo = response.data.inter_godown_details
            var bigCities = []
              for (let i = 0; i < toGodown.length; i++) {
                  if (toGodown[i].to_godown_infos_id) {
                      bigCities.push(toGodown[i].to_godown_infos_id)
                  }
              }
              var god = []
            for (let i = 0; i < bigCities.length; i++) {
                  const data = this.godownInfoList.find(doc => doc.value === bigCities[i])
                  if (data) {
                      god.push(data)
                  }
              }
            this.toGodownList = god
            this.getGodownInfo1(this.formData.to_godown_id)
          }
      })
      this.loading = false
    },
    getGodownInfo (godownId) {
      const data = this.godownInfoList.find(doc => doc.value === godownId)
      this.formData.district_name = this.getDataById(data.district_id, 'district')
      this.formData.region_name = this.getDataById(data.region_id, 'region')
      const trans = this.godownInfo.find(doc => doc.to_godown_infos_id === godownId)
      this.formData.transfer_able_amount = parseInt(trans.amount)
      this.formData.transfer_able_bag = trans.amount * 20
      this.transportAgentList = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.value === trans.transport_agent_id)
    },
    getGodownInfo1 (godownId) {
        const data = this.godownInfoList.find(doc => doc.value === godownId)
        const trans = this.godownInfo.find(doc => doc.to_godown_infos_id === godownId)
        this.formData = Object.assign({}, this.formData, {
            district_name: this.getDataById(data.district_id, 'district'),
            region_name: this.getDataById(data.region_id, 'region'),
            transfer_able_amount: trans.amount,
            transfer_able_bag: trans.amount * 20
        })
        this.transportAgentList = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.value === trans.transport_agent_id)
    },
    getDataById (Id, type) {
      if (Id) {
        var data
        switch (type) {
          case 'fertilizer':
            data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
            break
          case 'agent':
            data = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.value === Id)
            break
          case 'godown':
            data = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.value === Id)
            break
          case 'district':
            data = this.$store.state.commonObj.districtList.find(item => item.value === Id)
            break
          case 'region':
            data = this.$store.state.commonObj.officeList.find(item => item.value === Id)
            break
          default:
            break
        }
        if (data) {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        } else {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>
