<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('movement.inter_godown_fertilizer_shipment_challan') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-table-simple class="lori-table" borderless style="font-size:12px;color:#555555;">
                <tbody>
                  <tr>
                    <th style="width: 15%">{{ $t('movement.ChalanNo') }}</th>
                    <td style="width: 5%">:</td>
                    <td style="width: 25%">{{ formData.lori_challan_no }}</td>
                    <th style="width: 15%">{{ $t('movement.date') }}</th>
                    <td style="width: 5%">:</td>
                    <td style="width: 25%">{{ formData.allocation_date |dateFormat }}</td>
                  </tr>
                  <tr>
                    <th>{{  $t('movement.sender') }}</th>
                    <td>:</td>
                    <td></td>
                    <th>{{ $t('movement.lorryTruckLoadingSpace') }}</th>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.CNFName') }}</th>
                    <td>:</td>
                    <td>{{  currentLocale === 'bn' ? formData.cnf_agent_id_bn : formData.cnf_agent_id }}</td>
                    <th>{{ $t('movement.LorryTruckLeavingTime') }}</th>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.contactPhoneNo') }}</th>
                    <td>:</td>
                    <td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.cnf_agent_mobile, { useGrouping: false }) }}</td>
                    <th></th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.receiver_office') }}</th>
                    <td>:</td>
                    <td>{{  currentLocale === 'bn' ? formData.sale_center_bn : formData.sale_center }}</td>
                    <th>{{ $t('movement.driverNameAddress') }}</th>
                    <td>:</td>
                    <td>{{ formData.driver_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.receiver_godown') }}</th>
                    <td>:</td>
                    <td>{{  currentLocale === 'bn' ? formData.godown_infos_bn : formData.godown_infos }}</td>
                    <th></th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.ProgramNoDate') }}</th>
                    <td>:</td>
                    <td>{{ $n(formData.program_no, { useGrouping: false }) }} , {{ formData.allocation_date_program |dateFormat }}</td>
                    <th></th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.lorryTruckNo') }}</th>
                    <td>:</td>
                    <td>{{ formData.vehicle_no }}</td>
                    <th></th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.OwnerNameNAddress') }}</th>
                    <td>:</td>
                    <td> .......</td>
                    <th>{{  $t('movement.phoneNo') }}</th>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{{ $t('movement.phoneNo') }}</th>
                    <td>:</td>
                    <td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.phone_no, { useGrouping: false }) }}</td>
                    <th>{{ $t('movement.licenseNo') }}</th>
                    <td>:</td>
                    <td></td>
                  </tr>
                </tbody>
              </b-table-simple>
              <div class="col-12" style="padding-left: 10px;"><h6 class="p-0 m-0 font-weight-bold">{{ $t('movement.DetailsItemInfo') }}</h6></div>
            </b-row>
            <b-row>
              <b-table-simple bordered style="font-size:12px;margin-left:5px">
                <tr>
                  <th class="text-center">{{ $t('movement.fertilizerName')}}</th>
                  <th class="text-right">{{ $t('movement.numberOfBag')}}</th>
                  <th class="text-right">{{ $t('fertilizerReport.accountMayTons')}}</th>
                </tr>
                <tr>
                  <td class="text-center">{{  currentLocale === 'bn' ? formData.fertilizer_name_bn : formData.fertilizer_name }}</td>
                  <td class="text-right">{{ $n(formData.amount_of_bag, { useGrouping: false }) }}</td>
                  <td class="text-right">{{ $n(formData.amount_of_fertilizer, { useGrouping: false }) }}</td>
                </tr>
              </b-table-simple>
            </b-row>
            <div class="col-12" style="padding-left: 0px;"><h6 class="p-0 m-0 font-weight-bold">{{ $t('movement.inWords') }}: {{ currentLocale === 'en' ? convertNumberToEnglishWords(formData.amount_of_fertilizer) : convertNumberToBanglaWords(formData.amount_of_fertilizer) }} {{ $t('movement.metric_ton') }}</h6></div>
            <b-row class="text-black mt-4">
              <b-col lg="3" class="text-center">
                <hr class="bg-dark mx-1 mt-5">
                {{ $t('movement.driverSignature')}} <br>
              </b-col>
              <b-col lg="3" class="text-center">
                <hr class="bg-dark mx-1 mt-5">
                {{ $t('movement.BADCSignSeal')}}<br>
              </b-col>
              <b-col lg="3" class="text-center">
                <hr class="bg-dark mx-1 mt-5">
                {{ $t('movement.CNFSignatureSeal')}} <br>
              </b-col>
              <b-col lg="3" class="text-center">
                <hr class="bg-dark mx-1 mt-5">
                {{ $t('movement.receiverSignatureSeal')}}<br>
              </b-col>
            </b-row>
            <div class="second-part text-black mt-5">
              <b-table-simple class="table table-borderless">
                <b-tr>
                  <b-td colspan="4" class="text-center">{{ $t('movement.letter_of_acknowledgement') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="4" class="text-center">{{ $t('movement.ChalanNo') }} : {{ formData.lori_challan_no }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td width="25%">{{ $t('movement.receipt_stock_book_page_no') }} </b-td>
                  <b-td width="25%">:</b-td>
                  <b-td width="7%">{{ $t('globalTrans.date') }} </b-td>
                  <b-td>:</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ $t('movement.amount_of_receipt_fertilizer') }} </b-td>
                  <b-td>:</b-td>
                  <b-td>{{ $t('globalTrans.date') }} </b-td>
                  <b-td>:</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ $t('movement.amount_of_discrepancy') }} </b-td>
                  <b-td colspan="3">:</b-td>
                </b-tr>
              </b-table-simple>
              <b-row class="my-5">
                <b-col lg="4" class="text-center">
                  <hr class="bg-dark mx-1 mt-5">
                  {{ $t('movement.super_officer_signature_seal') }}<br>
                </b-col>
                <b-col lg="4" class="text-center">
                  <hr class="bg-dark mx-1 mt-5">
                  {{ $t('movement.deputy_assistant_director_signature_seal') }}<br>
                </b-col>
                <b-col lg="4" class="text-center">
                  <hr class="bg-dark mx-1 mt-5">
                  {{ $t('movement.assistant_director_signature_seal') }}<br>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{formData}}</pre> -->
    </b-row>
  </b-container>
</template>
<style scoped>
.lori-table th, td {
  margin-top: 1px  !important;
  margin-bottom: 1px  !important;
  padding-top: 1px  !important;
  padding-bottom: 1px  !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interGodownDeliveryShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
// import { loriChalanRecieveShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '../../../fertilizer-reports/pages/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
// import { Template } from 'webpack'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      this.getPortDetailsInfo()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        lori_challan_no: 0,
        program_no: '',
        transport_agent_id: 0,
        from_godown_id: 0,
        to_godown_id: 0,
        fertilizer_id: 0,
        fiscal_year_id: 1,
        vehicle_no: '',
        driver_name: '',
        district_name: '',
        from_godown_bn: '',
        from_godown: '',
        region_name: '',
        phone_no: '',
        transfer_able_amount: '',
        transfer_able_bag: '',
        amount_of_fertilizer: '',
        amount_of_bag: '',
        delivery_office_id: 0
      },
      programInfo: [],
      godownInfo: [],
      // transportAgentList: [],
      toGodownList: []
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    recieveProcessList: function () {
      const list = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      const listbn = [
        { value: 1, text: 'Manual' },
        { value: 2, text: 'Barcode' }
      ]
      return this.$i18n.locale === 'bn' ? listbn : list
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
     async getPortDetailsInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, interGodownDeliveryShow + this.id)
      if (result.success) {
        this.formData = result.data
        this.formData.cnf_agent_id = typeof result.transport !== 'undefined' ? result.transport.company_agent_name : ''
        this.formData.cnf_agent_id_bn = typeof result.transport !== 'undefined' ? result.transport.company_agent_name_bn : ''
        this.formData.cnf_agent_mobile = typeof result.transport !== 'undefined' ? result.transport.contact_person_mobile_no : ''
        const godown = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(fer => fer.value === this.formData.to_godown_id)
        this.formData.godown_infos = typeof godown !== 'undefined' ? godown.text_en : ''
        this.formData.godown_infos_bn = typeof godown !== 'undefined' ? godown.text_bn : ''
        const saleCenter = this.$store.state.commonObj.officeList.find(officeList => officeList.value === godown.sales_center_id)
        this.formData.sale_center = typeof saleCenter !== 'undefined' ? saleCenter.text_en : ''
        this.formData.sale_center_bn = typeof saleCenter !== 'undefined' ? saleCenter.text_bn : ''
        this.formData.allocation_date_program = result.info.transfer_date
        const fertilizer = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(fer => fer.value === result.info.fertilizer_id)
        this.formData.fertilizer_name = typeof fertilizer !== 'undefined' ? fertilizer.text_en : ''
        this.formData.fertilizer_name_bn = typeof fertilizer !== 'undefined' ? fertilizer.text_bn : ''
      }
    },
    getFormattedDate (date) {
      if (date) {
          const dateArr = date.split('-')
          return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
      } else {
        return date
      }
    },
    getDetailsData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getDataById (Id, type) {
      if (Id) {
        var data
        switch (type) {
          case 'fertilizer':
            data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
            break
          case 'agent':
            data = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.value === Id)
            break
          case 'district':
            data = this.$store.state.commonObj.districtList.find(item => item.value === Id)
            break
          case 'region':
            data = this.$store.state.warehouse.regionList.find(item => item.value === Id)
            break
          default:
            break
        }
        if (data) {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        } else {
          return null
        }
      } else {
        return null
      }
    },
    empty (str) {
      return (!str || str.length === 0)
    },
    convertNumberToEnglishWords (number) {
      if ((number < 0) || (number > 999999999)) {
        alert('Number is out of range')
        return
      }
      const numberArray = number.toString().split('.')
      const numberWithoutFloatingPart = numberArray[0] ? parseInt(numberArray[0]) : 0
      const numberWithFloatingPart = numberArray[1]
      let stringBeforeFloatingPart = ''
      if (numberWithoutFloatingPart) {
        stringBeforeFloatingPart = this.convertNumber(numberWithoutFloatingPart)
      }
      let numberWords = stringBeforeFloatingPart
      if (numberWithFloatingPart) {
        if (stringBeforeFloatingPart) {
          numberWords += stringBeforeFloatingPart + ' Point ' + this.convertNumber(parseInt(numberWithFloatingPart))
        } else {
          numberWords += 'Point ' + this.convertNumber(parseInt(numberWithFloatingPart))
        }
      }
      return numberWords
    },
    convertNumber (number) {
      const Kt = Math.floor(number / 10000000) /* Koti */
      number -= Kt * 10000000
      const Gn = Math.floor(number / 100000) /* lakh  */
      number -= Gn * 100000
      const kn = Math.floor(number / 1000) /* Thousands (kilo) */
      number -= kn * 1000
      const Hn = Math.floor(number / 100) /* Hundreds (hecto) */
      number -= Hn * 100
      const Dn = Math.floor(number / 10) /* Tens (deca) */
      const n = number % 10 /* Ones */

      let res = ''

      if (Kt) {
        res += this.convertNumber(Kt) + ' Koti '
      }
      if (Gn) {
        res += this.convertNumber(Gn) + ' Lakh'
      }

      if (kn) {
        res += (this.empty(res) ? '' : ' ') +
            this.convertNumber(kn) + ' Thousand'
      }

      if (Hn) {
        res += (this.empty(res) ? '' : ' ') +
            this.convertNumber(Hn) + ' Hundred'
      }

      const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six',
        'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen',
        'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eightteen',
        'Nineteen']
      const tens = ['', '', 'Twenty', 'Thirty', 'Fourty', 'Fifty', 'Sixty',
        'Seventy', 'Eigthy', 'Ninety']

      if (Dn || n) {
        if (!this.empty(res)) {
          res += ' and '
        }

        if (Dn < 2) {
          res += ones[Dn * 10 + n]
        } else {
          res += tens[Dn]

          if (n) {
            res += '-' + ones[n]
          }
        }
      }

      if (this.empty(res)) {
        res = 'zero'
      }

      return res
    },
    convertNumberToBanglaWords (amount) {
      const Words = ['', 'এক', 'দুই', 'তিন', 'চার', 'পাঁচ', 'ছয়', 'সাত', 'আট', 'নয়', 'দশ',
        'এগার', 'বার', 'তের', 'চৌদ্দ', 'পনের', 'ষোল', 'সতের', 'আঠার', 'ঊনিশ', 'বিশ',
        'একুশ', 'বাইশ', 'তেইশ', 'চব্বিশ', 'পঁচিশ', 'ছাব্বিশ', 'সাতাশ', 'আঠাশ', 'ঊনত্রিশ', 'ত্রিশ',
        'একত্রিশ', 'বত্রিশ', 'তেত্রিশ', 'চৌত্রিশ', 'পঁয়ত্রিশ', 'ছত্রিশ', 'সাঁইত্রিশ', 'আটত্রিশ', 'ঊনচল্লিশ', 'চল্লিশ',
        'একচল্লিশ', 'বিয়াল্লিশ', 'তেতাল্লিশ', 'চুয়াল্লিশ', 'পঁয়তাল্লিশ', 'ছেচল্লিশ', 'সাতচল্লিশ', 'আটচল্লিশ', 'ঊনপঞ্চাশ', 'পঞ্চাশ',
        'একান্ন', 'বায়ান্ন', 'তিপ্পান্ন', 'চুয়ান্ন', 'পঞ্চান্ন', 'ছাপ্পান্ন', 'সাতান্ন', 'আটান্ন', 'ঊনষাট', 'ষাট',
        'একষট্টি', 'বাষট্টি', 'তেষট্টি', 'চৌষট্টি', 'পঁয়ষট্টি', 'ছেষট্টি', 'সাতষট্টি', 'আটষট্টি', 'ঊনসত্তর', 'সত্তর',
        'একাতর', 'বাহাত্তর', 'তিয়াত্তর', 'চুয়াত্তর', 'পঁচাত্তর', 'ছিয়াত্তর', 'সাতাত্তর', 'আটাত্তর', 'ঊনআশি', 'আশি',
        'একাশি', 'বিরাশি', 'তিরাশি', 'চুরাশি', 'পঁচাশি', 'ছিয়াশি', 'সাতাশি', 'আটাশি', 'ঊননব্বই', 'নব্বই',
        'একানব্বই', 'বিরানব্বই', 'তিরানব্বই', 'চুরানব্বই', 'পঁচানব্বই', 'ছিয়ানব্বই', 'সাতানব্বই', 'আটানব্বই', 'নিরানব্বই']

      amount = amount.toString()
      const atemp = amount.split('.')
      let beforeWord = ''
      let afterWord = ''
      const beforeNumber = atemp[0]
      if (beforeNumber !== '0') {
        beforeWord = this.toWord(beforeNumber, Words)
      }
      if (atemp.length === 2) {
        const afterNumber = atemp[1]
        afterWord = this.toWord(afterNumber, Words)
        if (beforeWord !== '') {
          beforeWord += ' দশমিক ' + afterWord
        } else {
          beforeWord += 'দশমিক ' + afterWord
        }
      }
      return beforeWord
    },
    toWord (number, words) {
      var nLength = number.length
      var wordsString = ''

      if (nLength <= 9) {
        const nArray = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        const receivedNArray = []
        for (let i = 0; i < nLength; i++) {
          receivedNArray[i] = number.substr(i, 1)
        }
        for (let i = 9 - nLength, j = 0; i < 9; i++, j++) {
          nArray[i] = receivedNArray[j]
        }
        for (let i = 0, j = 1; i < 9; i++, j++) {
          if (i === 0 || i === 2 || i === 4 || i === 7) {
            if (nArray[i] === 1) {
              nArray[j] = 10 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 2) {
              nArray[j] = 20 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 3) {
              nArray[j] = 30 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 4) {
              nArray[j] = 40 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 5) {
              nArray[j] = 50 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 6) {
              nArray[j] = 60 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 7) {
              nArray[j] = 70 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 8) {
              nArray[j] = 80 + parseInt(nArray[j])
              nArray[i] = 0
            } else if (nArray[i] === 9) {
              nArray[j] = 90 + parseInt(nArray[j])
              nArray[i] = 0
            }
          }
        }

        var value = ''
        for (let i = 0; i < 9; i++) {
          value = nArray[i]
          if (value !== 0) {
            wordsString += words[value] + ''
          }
          if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && nArray[i + 1] === 0)) {
            wordsString += ' কোটি '
          }
          if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && nArray[i + 1] === 0)) {
            wordsString += ' লাখ '
          }
          if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && nArray[i + 1] === 0)) {
            wordsString += ' হাজার '
          } else if (i === 6 && value !== 0) {
            wordsString += 'শ '
          }
        }

        wordsString = wordsString.split('  ').join(' ')
      }
      return wordsString
    },
    pdfExport () {
        const fertilizer = this.getDataById(this.formData.fertilizer_id, 'fertilizer')
        const transport = this.getDataById(this.formData.transport_agent_id, 'agent')
        const reportTitle = this.$t('movement.inter_godown_fertilizer_shipment_challan')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, fertilizer, transport)
    }
  }
}
</script>

<style>
table tr {
 line-height: initial;
}
</style>
